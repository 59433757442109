@import "../../../styles/commonStyles/mixins.scss";
@import "../../../styles/commonStyles/variables.scss";

#pricing-plan {
    margin: 3.2rem 6.2rem;


    h1 {
        color: rgba(16, 24, 40, 1);
        font-size: 3rem;
        line-height: 3.6rem;
        font-weight: 600;
        margin-bottom: 2.2rem;
    }

    h2 {
        color: rgba(16, 24, 40, 1);
        font-size: 6rem;
        line-height: 7.2rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 6.4rem;
    }

    h3 {
        font-size: 2rem;
        line-height: 7.2rem;
        font-weight: 500;
        // text-align: center;
        margin-bottom: 1.4rem;
    }

    .radio_blk {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4.8rem;
        gap: 1rem;

        span {
            color: rgba(0, 0, 0, 0.50);
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 120%;

            &.active {
                color: #7F56D9;
            }

            /* 2.16rem */
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked+.slider {
            background-color: #6941C6;
        }

        input:focus+.slider {
            box-shadow: 0 0 1px #2196F3;
        }

        input:checked+.slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }

    .active-app {
        border: 2px solid $primary_color;
    }

    .pricing_card {
        box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
        padding: 2.4rem;
        background-color: #fff;
        border-radius: 0.8rem;
        transition: all ease-in-out .4s;

        &:hover {
            box-shadow: 0px 5px 30px 2px rgba(105, 65, 198, 0.20), 0px 2px 8px 0px rgba(158, 119, 237, 0.20);
        }

        h3 {
            color: rgba(26, 26, 26, 1);
            font-size: 2.7rem;
            line-height: 3.2rem;
            font-weight: 700;
            margin-bottom: 1.6rem;

            span {
                color: rgba(26, 26, 26, 1);
                font-size: 1.2rem;
                line-height: 1.4rem;
                font-weight: 400;
            }
        }

        p {
            color: #667085;
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 140%;
            /* 2.52rem */
        }

        hr {
            color: rgba(217, 217, 217, 1);
            margin: 3.2rem 0;
        }

        ul {
            li {
                position: relative;
                color: #1A1A1A;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 140%;
                margin-bottom: 1.6rem;
                display: block;
                display: flex;
                align-items: center;
                /* 2.52rem */

                .icon_blk {
                    margin-right: 1.2rem;
                }

                // &::after {
                //     background-image: url('../../../assets//images/checkicon.svg');
                //     background-repeat: no-repeat;
                //     // background-size: contain;
                //     width: 2.8rem;
                //     height: 2.8rem;
                //     // margin-right: 2rem;
                //     position: absolute;
                //     left: 2rem;

                // }


            }
        }

        .try_btn {
            max-width: 24rem;
            width: 100%;
            margin: 0 auto;
            margin-top: 3.2rem;
            display: flex;
            padding: 1.6rem 0.8rem;
            justify-content: center;
            align-items: center;
            gap: 0.4rem;
            border-radius: 0.4rem;
            border: 2px solid #9E77ED;

            background-color: #fff;

            color: #7F56D9;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            transition: all ease-in-out .3s;
            /* 2.52rem */

            &:hover {
                background-color: rgba(105, 65, 198, 1);
                color: #fff;
                border: 2px solid rgba(105, 65, 198, 1);

            }
        }

        .disabled-pricing-btn {
            max-width: 24rem;
            width: 100%;
            margin: 0 auto;
            margin-top: 3.2rem;
            display: flex;
            padding: 1.6rem 0.8rem;
            justify-content: center;
            align-items: center;
            gap: 0.4rem;
            border-radius: 0.4rem;
            border: 2px solid #9E77ED;
            cursor: auto;
            background-color: #000;

            color: #fff;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            transition: all ease-in-out .3s;
        }
    }

    .free_trial_blk {
        padding: 2.3rem 3.2rem;
        border-radius: 0.5rem;
        border: 1px solid #6941C6;
        background: #FFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6.8rem;

        .text {
            h4 {
                color: #6941C6;
                font-family: DM Sans;
                font-size: 3rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 3.6rem */
                margin-bottom: 1rem;
            }

            p {
                color: #1A1A1A;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 140%;
                /* 2.52rem */
                display: block;
                position: relative;
            }

            span {
                color: #1A1A1A;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 140%;
                /* 2.52rem */
                display: block;
                position: relative;
                margin-left: 4rem;

                &::after {
                    content: "";
                    display: block;
                    background-image: url('../../../assets/images/checkicon.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 2.8rem;
                    height: 2.8rem;
                    position: absolute;
                    left: -40px;
                    top: 0;

                }
            }
        }

        .subscribe_btn {
            display: flex;
            width: 24.225rem;
            padding: 1.6rem 0.8rem;
            justify-content: center;
            align-items: center;
            gap: 0.4rem;
            border-radius: 0.4rem;
            background: #6941C6;
            border: transparent;
            color: #FFF;
            font-size: 2rem;
            font-weight: 700;
            line-height: 140%;
            /* 2.8rem */
        }


    }

    .redeem_blk {
        padding: 2.3rem 3.2rem;
        border-radius: 0.5rem;
        border: 1px solid #6941C6;
        background: #6941C6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2.2rem;

        .text {
            h4 {
                color: #fff;
                font-size: 3rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 3.6rem */
                margin-bottom: 1rem;
            }

            span {
                color: #fff;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 140%;
                /* 2.52rem */
                display: block;
                // position: relative;
                // margin-left: 4rem;


            }
        }

        .subscribe_btn {
            display: flex;
            width: 24.225rem;
            padding: 1.6rem 0.8rem;
            justify-content: center;
            align-items: center;
            gap: 0.4rem;
            border-radius: 0.4rem;
            background: #fff;
            border: transparent;
            color: #6941C6;
            font-size: 2rem;
            font-weight: 700;
            line-height: 140%;
            /* 2.8rem */
        }


    }
}