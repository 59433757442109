@import "../../styles/commonStyles/mixins.scss";
@import "../../styles/commonStyles/variables.scss";

#panel_header_landing_page {
    background-color: $black;
    height: 8rem;
    padding: 0 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    position: fixed;
    width: 100%;
    z-index: 10;


    .panel-header-lifetime {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 2rem;
            font-weight: 500;
        }

        button {
            margin-left: 5rem;
            font-weight: 600;
        }
    }


    .update_btn {
        width: 22rem;
        height: 5.8rem;
        background-color: $secondary_color;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border: transparent;
        border-radius: .8rem;
        font-size: 2rem;
        font-weight: 500 !important;


        &:hover {
            border-color: $white;
        }

    }

    @media screen and (max-width: 768px) {
        .panel-header-lifetime {
            span {
                font-size: 1.5rem;
                font-weight: 500;
            }
        }

        .update_btn {
            width: 14rem;
            height: 4rem;
            font-size: 1.2rem;
            font-weight: 500 !important;
        }

        // padding-bottom: 5rem;
    }

    .profile_btn {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 75%;
        background-color: $primary_color;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.dropdown-toggle {
            &::after {
                display: none;
            }
        }

        i {
            color: $white;
            font-size: 1.8rem;
        }
    }
}

#hero_sec {
    background: $linear_bg;
    // height: 70rem;
    padding-bottom: 10rem;
    background-image: url("../../assets/images/hero_bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: auto;
    padding-top: 5rem;


    @media screen and (max-width: 768px) {
        // height: 65rem;
        padding-bottom: 5rem;
    }

    @media screen and (max-width: 580px) {
        // height: 60rem;
    }

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15rem 0;
        width: 70%;
        margin: 0 auto;

        @media screen and (max-width: 768px) {
            // padding: 8rem 0;
            width: 80%;
        }

        @media screen and (max-width: 580px) {
            width: 85%;
        }


        .main_head {
            font-family: 'Manrope', sans-serif;
            font-weight: 700;
            font-size: 7.8rem;
            line-height: 8.4rem;
            text-align: center;
            color: $white;
            // width: 50%;
            margin: 0 auto;
            text-align: center;
            margin-bottom: 2rem;


            @media screen and (max-width: 580px) {
                font-size: 4.8rem;
                line-height: 5.8rem;
            }


        }

        .desc {
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 165%;
            text-align: center;
            width: 60%;
            margin: 0 auto;
            color: $white_opacity;
        }

        .p-avatar-xl {
            width: 5rem;
            height: 5rem;
        }

        .avatar_text {
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.5rem;
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
            margin-top: 1rem;
        }

        .input_blk {
            border: transparent;
            width: 52.1rem;
            margin-top: 5rem;

            input {
                border-radius: 7.8rem;
                // border-radius: 4rem;
                height: 6.8rem;

            }


            .get_demo_btn {
                background-color: $black;
                color: $white;
                width: 14.3rem;
                height: 5.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 7.8rem;
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                z-index: 8;
            }
        }

        .feature_list {
            display: flex;
            align-items: center;
            margin-top: 3rem;
            justify-content: center;
            gap: 7rem;
            width: 100%;

            li {
                position: relative;
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 2.1rem;
                letter-spacing: 0.1px;
                color: $white;
                // margin-right: 5rem;


                &::before {
                    content: "";
                    display: block;
                    width: 3.8rem;
                    min-width: 3.8rem;
                    height: 3.8rem;
                    position: absolute;
                    left: -40px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-image: url(../../assets/images/transCheck.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 1rem;
                }
            }
        }

        @media screen and (max-width: 768px) {
            .feature_list {
                margin-left: 9rem;
            }
        }

    }

    .blog_writing {
        .article_card {
            background-color: $primary_color;
            border-radius: 3rem;
            padding: 5rem 4rem;
            cursor: pointer;

            @media screen and (max-width: 768px) {
                margin-bottom: 3rem;
            }

            &.pink {
                background-color: $secondary_color;
            }

            .card_inner {
                display: flex;
                justify-content: center;
                align-items: center;

                .icon_blk {
                    width: 3.5rem;
                    min-width: 3.5rem;
                    height: 3.5rem;
                    margin-right: 2.5rem;
                }

                .text_blk {
                    display: flex;
                    flex-direction: column;

                    h4 {
                        font-family: 'Manrope', sans-serif;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 3.2rem;
                        line-height: 3.8rem;
                        color: $white;
                        margin-bottom: 1rem;
                    }

                    p {
                        font-family: 'Manrope', sans-serif;
                        font-size: 2.4rem;
                        line-height: 2.8rem;
                        color: rgba(255, 255, 255, 0.76);
                        margin-bottom: 0;
                    }
                }
            }





        }
    }
}

// features sec //

#brands {
    margin-top: 8rem;

    @media screen and (max-width: 768px) {
        margin-top: 4rem;
    }

    p {
        color: rgba(36, 36, 36, 0.5);
        font-size: 2rem;
        font-weight: 400;
        line-height: 4.6rem;
        /* 230% */
        margin-bottom: 3.5rem;
        text-align: center;
    }

    .brands_list {
        display: flex;
        align-items: center;
        // margin-top: 3rem;
        justify-content: center;
        gap: 10rem;
        width: 100%;

        @media screen and (max-width: 768px) {
            // flex-direction: column;
            gap: 5;
        }

        li {
            // margin-right: 5rem;
            width: 5rem;
            height: 5rem;


            &::before {}
        }
    }
}

#productivity {
    margin-top: 10rem;
    background-image: url("../../assets/images/productivity_bg.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    // height: 63.7rem;
    width: 100%;
    height: 100%;



    .title {
        font-weight: 700;
        font-size: 5.8rem;
        line-height: 6.8rem;
        text-align: center;
        color: $black;
        margin: 0 auto;
        margin-bottom: 2rem;
    }

    p {
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 145%;
        text-align: center;
        color: $grey_text;
        margin: 0 auto;
        margin-bottom: 3.5rem;
        max-width: 54rem;
        width: 100%;
    }


    .coming_soon_btn {
        width: 19rem;
        height: 4.8rem;
        background: linear-gradient(90deg, #4442EF 0%, #FF9A3D 100%);
        border-radius: 5.8rem;
        border: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 3.2rem;
        letter-spacing: -0.02em;
        color: $white;
        margin: 0 auto;
        margin-bottom: 4rem;
    }

    .productivity_card {
        background: #F3F3F3;
        border-radius: 20px;
        padding: 2.2rem 3.3rem;
        height: 100%;
        position: relative;

        // margin: 2rem 0;

        @media screen and (max-width: 767px) {
            width: 50%;
            margin: 0 auto;
            margin-bottom: 3rem;
        }

        .tag {
            position: absolute;
            top: 2rem;
            right: 2rem;
            padding: 1rem 0;
            background: #000;
            width: 12rem;
            font-size: 1.2rem;
            line-height: 1.8rem;
            color: $white;
            border-radius: 2rem;

        }

        .icon_blk {
            width: 3.6rem;
            min-width: 3.6rem;
            height: 3.6rem;
            margin-bottom: 1rem;
        }

        h5 {
            font-weight: 600;
            font-size: 1.9rem;
            line-height: 145%;
            color: #1A1B1F;
            margin-bottom: 1.5rem;
            color: #1A1B1F;

        }

        .card_desc {
            font-weight: 400;
            font-size: 16px;
            line-height: 145%;
            color: #626262;
            margin-bottom: 0;
            text-align: left;
        }

    }

    .demo_btn {
        width: 13.2rem;
        height: 4.8rem;
        min-width: 13.2rem;
        margin: 0 auto;
        margin-top: 5rem;
        background: $black;
        border-radius: 5.8rem;
        border: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 3.2rem;
        letter-spacing: -0.02em;
        color: $white;
    }
}

#seo_scores {
    margin-top: 12rem;

    .inner {
        display: flex;
        align-items: center;
        // gap: 20rem;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 5em;
        }

        .text_blk {
            max-width: 42.3rem;
            width: 100%;
            margin: 0 auto;

            @media screen and (max-width: 767px) {
                margin: 0 auto;
                max-width: initial;
                width: initial;
            }

            h2 {
                font-weight: bold;
                font-size: 5.8rem;
                line-height: 6.8rem;
                color: rgba(36, 36, 36, 1);

                span {
                    color: rgba(94, 202, 117, 1);
                }

                @media screen and (max-width: 767px) {
                    //  margin: 0 auto;
                    text-align: center;
                }
            }

            p {
                color: #626262;
                font-size: 1.8rem;
                line-height: 145%;
                /* 2.61rem */
                max-width: 33.3rem;
                width: 100%;

                @media screen and (max-width: 767px) {
                    margin: 0 auto;
                    max-width: 50rem;
                    // width: initial;
                    text-align: center;
                }
            }
        }

        .img_blk {
            max-width: 31.5006rem;
            width: 100%;
            margin-right: auto;

            @media screen and (max-width: 767px) {
                margin: 0 auto;
            }
        }
    }
}

#cheapest {
    margin-top: 13rem;

    .inner {
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
            flex-direction: column-reverse;
            gap: 5em;
        }

        .img_blk {
            max-width: 33.2rem;
            width: 100%;
            margin: 0 auto;

            @media screen and (max-width: 767px) {
                margin: 0 auto;
            }
        }

        .text_blk {
            max-width: 44.9rem;
            width: 100%;
            margin-right: auto;

            @media screen and (max-width: 767px) {
                margin: 0 auto;
                text-align: center;
                width: initial;
                max-width: initial;
            }


            h2 {
                color: #242424;
                font-size: 5.8rem;
                font-weight: bold;
                line-height: 6.8rem;

                span {
                    color: $primary_color;
                }
            }

            p {
                color: #626262;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 145%;

                @media screen and (max-width: 767px) {
                    margin: 0 auto;
                    max-width: 50rem;
                    // width: initial;
                    text-align: center;
                }
            }
        }
    }
}

#compare_plans {
    margin-top: 13rem;

    h1 {
        color: #242424;
        text-align: center;
        font-size: 5.8rem;
        font-weight: 700;
        line-height: 6.8rem;
        margin-bottom: 1rem;
    }

    span {
        color: #626262;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 145%;
        text-align: center;
        margin-bottom: 3rem;
        display: block;
        /* 2.61rem */
    }

    .inner {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;


        .compare_card {
            border-radius: 2rem;
            border: 1px solid #D4D4D4;
            background: #FFF;
            height: 100%;

            &.card1 {
                // padding: 0 2.5rem;
                padding: 2.5rem;
                flex: 0 0 25%;
                height: 100%;

                ul {
                    // margin-top: 13.3rem;
                    list-style-type: none;
                    margin-bottom: 0;
                    padding-left: 0;

                    li {
                        color: #5B5675;
                        font-size: 1.8rem;
                        font-weight: 500;
                        margin-bottom: 2rem;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                }
            }

            &.card2 {
                flex: 0 0 15%;
                padding: 2.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    color: #000;
                    font-size: 2.4rem;
                    font-weight: 700;
                    line-height: 3.4rem;
                    margin-bottom: 2rem;
                }

                p {
                    color: #DB2323;
                    font-size: 1.6rem;
                    font-weight: 700;
                    line-height: 3.4rem;
                    margin-bottom: 2rem;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    li {
                        margin-bottom: 2rem;

                        svg {
                            margin: 0 auto;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .demo_btn {
                    display: flex;
                    padding: 0.8rem 2.4rem;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5.8rem;
                    background: $primary_color;
                    border: transparent;
                    color: #FFF;
                    font-size: 1.8rem;
                    font-weight: 700;
                    line-height: 3.2rem;
                    /* 177.778% */
                    letter-spacing: -0.036rem;
                }
            }

            &.card3 {
                flex: 0 0 15%;
                padding: 2.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    color: #000;
                    font-size: 2.4rem;
                    font-weight: 700;
                    line-height: 3.4rem;
                    margin-bottom: 2rem;
                }

                p {
                    color: #DB2323;
                    font-size: 1.6rem;
                    font-weight: 700;
                    line-height: 3.4rem;
                    margin-bottom: 2rem;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    li {
                        margin-bottom: 2rem;

                        svg {
                            margin: 0 auto;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .demo_btn {
                    display: flex;
                    padding: 0.8rem 2.4rem;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5.8rem;
                    background: $primary_color;
                    border: transparent;
                    color: #FFF;
                    font-size: 1.8rem;
                    font-weight: 700;
                    line-height: 3.2rem;
                    /* 177.778% */
                    letter-spacing: -0.036rem;
                }
            }

            &.card4 {
                flex: 0 0 15%;
                padding: 2.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                h3 {
                    color: #000;
                    font-size: 2.4rem;
                    font-weight: 700;
                    line-height: 3.4rem;
                    margin-bottom: 2rem;
                }

                p {
                    color: #DB2323;
                    font-size: 1.6rem;
                    font-weight: 700;
                    line-height: 3.4rem;
                    margin-bottom: 2rem;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    li {
                        margin-bottom: 2rem;

                        svg {
                            margin: 0 auto;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .demo_btn {
                    display: flex;
                    padding: 0.8rem 2.4rem;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5.8rem;
                    background: $primary_color;
                    border: transparent;
                    color: #FFF;
                    font-size: 1.8rem;
                    font-weight: 700;
                    line-height: 3.2rem;
                    /* 177.778% */
                    letter-spacing: -0.036rem;
                }
            }
        }
    }
}

#reviews {
    margin-top: 10rem;

    .title {
        font-weight: 700;
        font-size: 5.8rem;
        line-height: 6.8rem;
        text-align: center;
        color: $black;
        margin: 0 auto;
        margin-bottom: 2rem;
    }

    p {
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 145%;
        text-align: center;
        color: $grey_text;
        margin: 0 auto;
        margin-bottom: 3.5rem;
        max-width: 54rem;
        width: 100%;
    }

    .slick-track {
        display: flex;
    }

    .slick-slide {
        margin: 0 2rem;
    }

    .slick-arrow {
        background-color: $black;
        width: 5rem;
        min-width: 5rem;
        height: 5rem;
        border-radius: 75%;
        display: flex !important;
        align-items: center;
        justify-content: center;

        i {
            font-size: 2rem;
            color: $white;
        }
    }

    .slick-next {
        color: $black;
        z-index: 8 !important;
        right: 45%;
        color: #000;
        z-index: 10;
        transform: translate(-45%, -135%);
        top: 135%;

        @media screen and (max-width: 768px) {
            top: 125%;
            right: 38%;
            transform: translate(-42%, -125%);
        }

        &::before {
            display: none;
        }
    }

    .slick-prev {
        left: 47%;
        color: #000;
        z-index: 8;
        transform: translate(-47%, -135%);
        top: 135%;

        @media screen and (max-width: 768px) {
            top: 125%;
            transform: translate(-50%, -125%);

        }

        &::before {
            display: none;
        }
    }





    .review_card {
        background: #F3F3F3;
        border-radius: 1.2rem;
        padding: 4rem;

        .review_text {
            font-weight: 400;
            font-size: 2.4rem;
            line-height: 3.6rem;
            color: $black;
            margin-bottom: 6rem;
        }

        .profile_blk {
            display: flex;
            align-items: center;

            .img_blk {
                width: 6rem;
                min-width: 6rem;
                height: 6rem;
                margin-right: 1.5rem;
                border-radius: 75%;
                overflow: hidden;

                img {
                    object-fit: cover;
                }
            }

            .text_blk {
                h6 {
                    font-weight: 700;
                    font-size: 1.6rem;
                    line-height: 2.1rem;
                    color: #0A2640;
                    margin-bottom: .6rem;
                }

                span {
                    font-weight: 400;
                    font-size: 1.4rem;
                    line-height: 3.2rem;
                    color: #0A2640;
                }
            }
        }
    }

}

#pricing {
    margin: 22rem 0 20rem;

    .title {
        font-weight: 700;
        font-size: 5.8rem;
        line-height: 6.8rem;
        text-align: center;
        color: $black;
        margin: 0 auto;
        margin-bottom: 2rem;
    }

    p {
        font-weight: 600;
        font-size: 1.8rem;
        line-height: 145%;
        text-align: center;
        color: $grey_text;
        margin: 0 auto;
        margin-bottom: 3.5rem;
        max-width: 54rem;
        width: 100%;
    }

    .price_card_col {
        display: flex;

        @media screen and (max-width: 768px) {
            // flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 4rem;
        }
    }

    .react-tabs__tab-list {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
    }


    .second_tab {
        width: 20rem;
        height: 3.4rem;
        border: transparent;
        border-radius: 1rem;
        color: $black;
        display: flex;
        background: #F4F4F7;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 3.4rem;
        text-align: center;
        letter-spacing: -0.02em;
        margin-right: .5rem;

        &.react-tabs__tab--selected {
            background-color: $primary_color;
            color: $white;
        }
    }

    .react-tabs__tab {
        width: 10rem;
        width: 16rem;
        height: 3.4rem;
        border: transparent;
        border-radius: 1rem;
        color: $black;
        display: flex;
        background: #F4F4F7;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 3.4rem;
        text-align: center;
        letter-spacing: -0.02em;
        margin-right: .5rem;

        &.react-tabs__tab--selected {
            background-color: $primary_color;
            color: $white;
        }
    }

    .pricing_cards {
        display: flex;
        flex-direction: column;
        padding: 2.5rem;
        background: #FFFFFF;
        border-radius: 20px;
        border: 1px solid #D4D4D4;
        border-radius: 2rem;
        transition: all ease-in-out .3s;
        cursor: pointer;
        position: relative;

        &:hover {
            border-color: $primary_color;
            box-shadow: 0px 8px 16px 0px rgba(50, 59, 245, 0.25);
        }


        h6 {
            font-size: 2.4rem;
            line-height: 3rem;
            font-weight: 600;
            color: $black;

        }

        .free_tag {
            color: $black;
            font-size: 1.6rem;
            line-height: 2.1rem;
            margin: 2rem 0;
            text-align: left;

            b {
                font-size: 2.1rem;
            }
        }

        .pricing_tag {
            font-size: 1.6rem;
            line-height: 2.1rem;
            color: #5B5675;
            // margin-bottom: 2rem;

            @media screen and (max-width: 768px) {
                width: 28rem;
                margin: 0 auto;
            }
        }

        ul {
            margin-top: 4rem;
            list-style-type: disc;

            li {
                margin-bottom: 1rem;
                display: flex;
                align-items: center;



                .points {
                    color: #5B5675;
                    font-size: 1.8rem;
                    line-height: 2.1rem;

                    a {
                        color: $primary_color;
                        font-size: 1.4rem;
                    }

                }
            }

            h3 {
                color: $black;
                font-size: 1.8rem;
            }
        }



        .card_btn {
            background-color: $primary_color;
            color: $white;
            font-size: 1.8rem;
            line-height: 2.1rem;
            font-weight: 700;
            width: 13.2rem;
            min-width: 13.2rem;
            margin: 0 auto;
            height: 5rem;
            border: .1rem solid transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
            cursor: pointer;
            border-radius: 5.8rem;
            transition: all ease-in-out 0.3s;

            &:hover {
                border-color: $primary_color;
            }
        }

        .info {
            text-align: center;
            display: block;
            margin-top: 2rem;
            cursor: pointer;
            color: $black;
        }
    }
}

// #pricing_details {

//     .title {
//         font-size: 5.1rem;
//         line-height: 6.4rem;
//         color: $black;
//         text-align: center;
//         margin-bottom: 5rem;


//         @media screen and (max-width: 769px) {
//             font-size: 3.1rem;
//             line-height: 4.1rem;
//         }
//     }

//     .table_outer {
//         overflow-x: auto;
//         border: .1rem solid $gray_color;
//         border-radius: .8rem;
//         width: 100%;
//         margin-bottom: 5rem;

//         .plans_table {
//             width: 100%;

//             thead {
//                 background-color: $secondary_color;

//                 th {
//                     flex: 1;


//                     td {
//                         padding: 2rem;

//                         h3 {
//                             color: $white;
//                             font-size: 2.1rem;
//                             margin-bottom: 0;

//                         }
//                     }
//                 }
//             }

//             tbody {
//                 tr {
//                     // border: .1rem solid $gray_color;

//                     td {
//                         padding: 2rem;
//                         text-align: justify;
//                         color: $black;

//                         i {
//                             font-size: 2.4rem;
//                             color: green;
//                         }
//                     }
//                 }
//             }
//         }
//     }


// }



#what_next {
    margin-bottom: 8.5rem;
    // margin-top: 20rem;

    .inner {
        background: #EAEBFF;
        border-radius: 3rem;
        padding: 5.5rem 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h2 {
            color: #242424;
            text-align: center;
            font-size: 4rem;
            font-weight: 700;
        }

        p {
            color: #626262;
            text-align: center;
            font-size: 1.8rem;
            font-weight: 600;
            line-height: 145%;
            /* 2.61rem */
            margin-bottom: 3.5rem;
            width: 54rem;
        }

        .demo_btn {
            width: 13.2rem;
            height: 4.8rem;
            min-width: 13.2rem;
            margin: 0 auto;
            background: $primary_color;
            border-radius: 5.8rem;
            border: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 3.2rem;
            letter-spacing: -0.02em;
            color: $white;
        }

    }
}


.title {
    font-size: 3.6rem;
    line-height: 4.4rem;
    font-weight: 600;
    color: $black;
    margin-bottom: 5rem;
    text-align: center;
}