#MyDocuments {
	margin: 3rem 3.2rem;

	@media screen and (max-width: 767px) {
		margin: 3rem 2rem;
	}

	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 5.5rem;

		h2 {
			color: #101828;
			font-size: 3rem;
			font-weight: 600;
			line-height: 3.8rem;
		}

		.add_img {
			width: 17rem;
			height: 5rem;
			background-color: #101828;
			color: #fff;
			font-size: 1.6rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			justify-content: center;
			border: transparent;
			border-radius: 2.5rem;
		}
	}

	.document_sec {
		margin-top: 4rem;

		.document_card_blk {
			display: flex;
			flex-wrap: wrap;
			// justify-content: center;
			gap: 2rem;
			margin-top: 2.5rem;

			.document_card {
				position: relative;
				flex: 0 0 28%;
				border-radius: 0.8rem;
				border: 1px solid #eaecf0;
				background: #fff;
				padding: 2rem 1.5rem;

				@media screen and (max-width: 767px) {
					flex: 0 0 100%;
				}

				h2 {
					color: #101828;
					font-size: 1.6rem;
					font-weight: 600;
					line-height: 2.8rem;
					margin-bottom: 0.5rem;
					max-width: 100%;
					width: 100%;
				}

				.date {
					color: #475467;
					/* Text sm/Regular */
					font-size: 1.4rem;
					font-weight: 400;
					line-height: 2rem;
				}

				.info_icon {
					position: absolute;
					top: 20px;
					right: 15px;
				}
			}
		}
	}

	// modal //

	.App {
		text-align: center;
		padding-top: 2rem;
	}

	.modal-overlay {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1040;
		width: 100vw;
		height: 100vh;
		background-color: #000;
		opacity: 0.5;
	}

	.modal-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1050;
		width: 100%;
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		outline: 0;
	}

	.modal {
		z-index: 100;
		background: white;
		position: relative;
		margin: 1.75rem auto;
		border-radius: 3px;
		max-width: 500px;
		padding: 2rem;
	}

	.modal-header {
		display: flex;
		justify-content: flex-end;
	}

	.modal-close-button {
		font-size: 1.4rem;
		font-weight: 700;
		line-height: 1;
		color: #000;
		opacity: 0.3;
		cursor: pointer;
		border: none;
	}

	button {
		font-size: 0.9rem;
		font-weight: 700;
		border: none;
		border-radius: 3px;
		padding: 0.3rem 1rem;
		margin-left: 0.5rem;
	}

	.button-default {
		background: #247ba0;
		color: #fff;
	}
}
