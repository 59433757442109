@import "../../styles/commonStyles/variables.scss";

#zapier {
	margin: 3rem 3.2rem;

	@media screen and (max-width: 767px) {
		margin: 3rem 2rem;
	}

	h2 {
		color: #101828;
		font-size: 3rem;
		font-weight: 600;
		line-height: 3.8rem;
		margin-bottom: 5.5rem;
	}

	.inner {
		padding: 3rem 14rem;

		@media screen and (max-width: 767px) {
			padding: 3rem 3.2rem;
		}

		h3 {
			color: $black;
			font-size: 3.2rem;
			font-weight: 600;
			line-height: 3.8rem;
			margin-bottom: 1rem;
			/* 75% */
		}

		p {
			color: $black;
			font-size: 1.8rem;
			font-style: normal;
			font-weight: 300;
			line-height: 2.4rem;
			/* 133.333% */
		}

		.zapier_card {
			cursor: pointer;
			border-radius: 1.8rem;
			border: 2px solid rgba(140, 140, 140, 0.25);
			background: #fff;
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
			padding: 2rem 2.5rem;
			max-width: 53.2rem;
			width: 100%;
			margin-top: 7rem;

			.top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 3rem;

				@media screen and (max-width: 580px) {
					flex-direction: column;
					justify-content: flex-start;
					gap: 3rem;
				}

				.icon_sec {
					display: flex;
					align-items: center;
					gap: 1.8rem;

					.icon_blk {
						width: 5.4rem;
						min-width: 5.4rem;
						height: 5.4rem;
					}
				}

				h4 {
					color: $black;
					font-size: 2.5rem;
					font-weight: 600;
					line-height: 2.4rem;
					/* 96% */
				}

				.connect_btn {
					cursor: pointer;
					border-radius: 0.8rem;
					border: 2px solid rgba(140, 140, 140, 0.25);
					background: #fff;
					padding: 1rem 1.4rem;
					color: $black;
					font-size: 2rem;
					font-weight: 500;
					line-height: 2.4rem;
					/* 120% */
				}
			}

			p {
				color: $black;
				font-size: 1.8rem;
				font-style: normal;
				font-weight: 300;
				line-height: 2.4rem;

				/* 133.333% */
				@media screen and (max-width: 580px) {
					text-align: center;
				}

				a {
					text-decoration: underline !important;
				}
			}
		}
	}
}
