@import "../../../styles/commonStyles/variables.scss";

#zapier_guide {
    margin: 3rem 3.2rem;

    @media screen and (max-width: 767px) {
        margin: 2rem;
    }

    h2 {
        color: #101828;
        font-size: 3rem;
        font-weight: 600;
        line-height: 3.8rem;
        margin-bottom: 5.5rem;

        @media screen and (max-width: 767px) {
            margin-bottom: 2rem;
        }
    }

    .inner {
        padding: 3rem 14rem;

        @media screen and (max-width: 992px) {
            padding: 2rem 6rem;
        }

        @media screen and (max-width: 767px) {
            padding: 2rem;
        }

        h3 {
            color: #000;
            font-size: 3.2rem;
            font-style: normal;
            font-weight: 600;
            line-height: 4rem;
            margin-bottom: 3rem;
            /* 125% */
        }

        ol {
            list-style-type: decimal;

            li {
                color: #000;
                font-size: 2.2rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2.5rem;
                margin-bottom: 1.1rem;
                /* 78.125% */
            }
        }

        .steps {
            padding-left: 3rem;
            margin-bottom: 6rem;

            h3 {
                margin-bottom: 1rem !important;
            }

            p {
                color: #000;
                font-size: 2.2rem;
                font-style: normal;
                font-weight: 300;
                line-height: 4rem;
                margin-bottom: 1.4rem;
                /* 125% */
            }

            .inner_text {
                padding-left: 3rem;

                p {
                    b {
                        color: #000;
                        font-size: 2.6rem;
                        font-weight: 600;
                        line-height: 4rem;
                    }
                }
            }
        }
    }
}