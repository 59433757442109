@import "../../styles/commonStyles/mixins.scss";
@import "../../styles/commonStyles/variables.scss";

#footer {

    padding-bottom: 5rem;


    .footer_top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;

        .logo_sec {
            .logo_blk {
                min-width: 10rem;
                width: 10rem;
                height: 5rem;

                img {
                    object-fit: contain;
                }
            }

            .slogan {
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 2rem;
                color: rgba(17, 17, 20, 0.7);
                margin-top: 1rem;
                margin-bottom: 0;
                max-width: 20.6rem;
                width: 100%;
            }
        }


        .social_blk {
            display: flex;
            align-items: center;

            li {
                margin-right: 2rem;

                &:last-child {
                    margin-right: 0;
                }

                .icon_blk {
                    display: block;
                    width: 3rem;
                    min-width: 3rem;
                    height: 3rem;
                }
            }

        }
    }

    .footer_bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .links {
            display: flex;
            align-items: center;

            li {
                margin-right: 5rem;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    color: #23294A;
                }
            }

        }

        .rights {

            font-style: normal;
            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2rem;
            color: #23294A;

        }
    }
}

// #footer {

//     background-color: #343a40;
//     padding: 8rem 0;

//     .logo_blk {
//         width: 100%;

//         @media screen and (max-width: 768px) {
//             width: 30%;
//             margin: 0 auto;
//             margin-bottom: 3rem;
//         }


//         img {
//             object-fit: initial;
//         }
//     }

//     ul {
//         @media screen and (max-width: 768px) {
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: center;
//             margin-bottom: 3rem;

//         }

//         li {
//             margin-bottom: 2rem;


//             a {
//                 color: $white;
//                 font-size: 1.6rem;
//                 line-height: 2rem;

//                 &:hover {
//                     color: $black;
//                 }
//             }
//         }
//     }

//     .social_media_icons {
//         display: flex;
//         align-items: center;

//         @media screen and (max-width: 768px) {
//             display: flex;
//             flex-direction: row;
//             align-items: center;
//             justify-content: center;

//         }

//         li {
//             margin-right: 4rem;

//             &:last-child {
//                 margin-right: 0;
//             }

//             a {
//                 font-size: 3rem;
//                 color: $white;
//             }
//         }
//     }


// }