@import "../../../styles/commonStyles/mixins.scss";
@import "../../../styles/commonStyles/variables.scss";

#login {
    position: relative;
    height: 100vh;
    // width: 50%;
    // display:flex;
    // justify-content: center;
    // align-items: center;
    .logo_blk {
        width: 20rem;
        height: 10rem;
        min-width: 10rem;
        margin: 0 auto;
        margin-bottom: 3rem;

        img {
            object-fit: contain;
        }
    }
    h2 {
        text-align: center;
        margin-bottom: 1rem;
        color: $black;

    }

}




.signup_btn {
    text-align: center;
    border: transparent;
    border-radius: .8rem;
    width: 50%;
    height: 5rem;
    background-color: $primary_color;
    color: $white;
    font-size: 2.1rem;
    line-height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    cursor: pointer;
}
.signup_form {
    max-width: 40rem;
    width: 100%;
    margin: 0 auto;

    label {
        color: rgba(0, 0, 0, 1);
        font-size: 1.5rem;
        margin-top: 3rem;
        font-weight: 500;
        line-height: 1.2rem;
        letter-spacing: 0.03rem;
    }

    .input_blk {
        width: 36rem;
        input {
            background-color: #f2f2f2;

            &::placeholder {
                color: rgba(128, 128, 128, 1);
            }
        }

    }
}