@import "../../styles/commonStyles/variables.scss";

#panel_header {
    background-color: $white;
    height: 6rem;
    padding: 0 4rem;
    display: none;

    i {
        font-size: 2rem;
        color: $black;
    }

    @media screen and (max-width: 767px) {
        display: flex;
        align-items: center;
    }

    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    // @media screen and (max-width: 480px) {
    //     height: 8rem;
    // }

    // @media screen and (max-width: 767px) {
    //     padding: 0 2rem;
    // }



    // .panel-header-lifetime {
    //     display: flex;
    //     justify-content: space-evenly;
    //     align-items: center;


    //     @media screen and (max-width: 767px) {
    //         max-width: 100%;
    //         width: 100%;
    //     }

    //     .mob_menu_btn {
    //         display: none;

    //         @media screen and (max-width: 767px) {
    //             display: block;
    //             background-color: initial;
    //             border: initial;
    //             box-shadow: initial;
    //             margin-right: 2rem;

    //             i {
    //                 color: $white;
    //                 font-size: 2rem;
    //             }
    //         }


    //     }

    //     span {
    //         font-weight: normal;
    //         font-size: 1.4rem;

    //         @media screen and (max-width: 480px) {
    //             padding: 1rem 0;
    //         }

    //     }

    //     button {
    //         // margin-left: 5rem;
    //         font-weight: 600;
    //     }
    // }


    // .update_btn {
    //     min-width: 14rem;
    //     height: 4rem;
    //     background-color: $secondary_color;
    //     color: $white;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     border: transparent;
    //     border-radius: .8rem;
    //     font-size: 1.4rem;

    //     @media screen and (max-width: 767px) {
    //         margin: 0 2rem;
    //     }

    //     &:hover {
    //         border-color: $white;
    //     }

    // }

    // .profile_btn {
    //     width: 4.5rem;
    //     height: 4.5rem;
    //     border-radius: 75%;
    //     background-color: $primary_color;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     cursor: pointer;

    //     &.dropdown-toggle {
    //         &::after {
    //             display: none;
    //         }
    //     }

    //     i {
    //         color: $white;
    //         font-size: 1.8rem;
    //     }
    // }
}