@import "../../../styles/commonStyles/mixins.scss";
@import "../../../styles/commonStyles/variables.scss";

#forgot {

    margin: 6rem auto 0 auto;

    .logo_blk {
        width: 20rem;
        height: 10rem;
        min-width: 10rem;
        margin: 0 auto;
        margin-bottom: 3rem;

        img {
            object-fit: contain;
        }
    }

    h2 {
        text-align: center;
        margin-bottom: 1rem;
        color: $black;

    }

    .grey_text {
        width: 60%;
        margin: 0 auto;
        color: #A0AEC0;
        font-size: 1.6rem;
        line-height: 2.1rem;
        text-align: center;
        margin-bottom: 3rem;
    }

    .signup_form {
        max-width: 50rem;
        width: 100%;
        margin: 0 auto;

        .check_mark {
            display: flex;
            // align-items: center;

            .checkbox {
                height: 2.5rem;
                width: 2.5rem;
                margin-right: 1.5rem;
            }

            .terms_text {
                font-size: 1.6rem;
                line-height: 2.1rem;
                color: lightgray;

            }
        }

        .login_acc {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.6rem;
            line-height: 2.1rem;
            color: $black;
            font-weight: 400;
            margin-bottom: 3rem;


            .blue_text {
                color: $primary_color;
                font-weight: 600;
            }
        }

        .google_login {
            display: flex;
            align-items: center;
            width: 50%;
            margin: 0 auto;
            font-size: 1.8rem;
            line-height: 2.4rem;
            color: $black;
            padding: 0 2rem;
            border-radius: .8rem;
            background-color: $white;
            border: .1rem solid #D6D6D7;
            height: 5rem;
            cursor: pointer;
            margin-bottom: 2.5rem;

            &:hover {
                border-color: $primary_color
            }

            .icon_blk {
                width: 3rem;
                min-width: 3rem;
                height: 3rem;
                margin-right: 1rem;

                img {
                    object-fit: contain;
                }
            }
        }

        .forgot {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 2rem;
        }

        .signup_btn {
            border: transparent;
            border-radius: .8rem;
            width: 100%;
            height: 5rem;
            background-color: $primary_color;
            color: $white;
            font-size: 2.1rem;
            line-height: 2.4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 3rem;
            cursor: pointer;
        }
    }
}