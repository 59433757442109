@import "../../styles/commonStyles/mixins.scss";
@import "../../styles/commonStyles/variables.scss";

#sidebar {
  max-width: 28rem;
  width: 100%;
  padding: 3.2rem 2.4rem;
  border-right: 1px solid rgb(234, 236, 240);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;


  @media screen and (max-width:767px) {
    left: -28rem;
  }

  &.active {
    left: 0;
    z-index: 10;
    background-color: $white;
    transition: all ease-in-out 0.3s;

  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .top_sec {
    .logo_blk {
      margin-bottom: 3.2rem;
      max-width: 13rem;
      width: 100%;
    }

    .menu_list {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 1.2rem;
        padding: 0.8rem 1.2rem;
        transition: all ease-in-out 0.2s;

        &.active {
          background-color: rgb(226, 226, 226);
        }

        &:hover {
          background-color: rgba(249, 250, 251, 1);
        }

        svg {
          margin-right: 1.2rem;
        }

        .icon_blk {
          width: 2rem;
          min-width: 2rem;
          height: 2rem;
          margin-right: 1.2rem;
        }

        a {
          color: rgba(16, 24, 40, 1);
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 2.4rem;
        }

        span {
          color: rgba(16, 24, 40, 1);
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 2.4rem;
        }
      }
    }
  }

  .bottom_sec {
    .menu_list {
      li {
        display: flex;
        align-items: center;
        margin-bottom: 1.2rem;
        padding: 0.8rem 1.2rem;
        transition: all ease-in-out 0.2s;

        &:hover {
          background-color: rgba(249, 250, 251, 1);
        }

        svg {
          margin-right: 1.2rem;
        }

        .icon_blk {
          width: 2rem;
          min-width: 2rem;
          height: 2rem;
          margin-right: 1.2rem;
        }

        span {
          color: rgba(16, 24, 40, 1);
          font-size: 1.8rem;
          font-weight: 600;
          line-height: 2.4rem;
        }
      }
    }

    .refer_blk {
      background-color: rgba(249, 250, 251, 1);
      padding: 2rem 1.6rem;

      h4 {
        color: rgba(16, 24, 40, 1);
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
        margin-bottom: 1.6rem;
      }

      .progress_blk {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        margin-bottom: 1.6rem;

        progress {}

        span {
          font-size: 1.4rem;
          line-height: 2rem;
          font-weight: 500;
          color: rgba(52, 64, 84, 1);
        }
      }

      p {
        color: rgba(71, 84, 103, 1);
        font-size: 1.4rem;
        line-height: 2rem;
        margin-bottom: 1.6rem;
      }

      .refer_link {
        color: $primary_color;
      }

      span {
        color: rgba(71, 84, 103, 1);
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
        cursor: pointer;
      }

      a {
        color: rgba(71, 84, 103, 1);
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .upgrade_blk {
      background-color: rgba(242, 246, 250, 1);
      padding: 2.5rem 0.8rem 0;
      margin-bottom: 2.4rem;
      margin-top: 2.4rem;

      h2 {
        color: rgba(91, 94, 111, 1);
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 3rem;

        b {
          font-weight: 700;
        }
      }

      .img_sec {
        margin-top: 1rem;
        display: flex;
        width: 100%;
        background: url("../../assets//images//upgradeImage.png");
        background-repeat: no-repeat;
        // background-position: right;
        background-size: contain;

        background-position: right;
        height: 19rem;

        p {
          color: rgba(146, 148, 160, 1);
          font-size: 1.4rem;
          line-height: 2.1rem;
          font-weight: 400;
          width: 50%;

          b {
            font-weight: 700;
          }
        }

        .img_blk {
          background: url("../../assets//images//upgradeImage.png");
          background-repeat: no-repeat;
          // background-position: right;
          background-size: contain;
          width: 10.6rem;
          height: 19rem;
          display: none;
        }
      }
    }

    hr {
      color: rgba(234, 236, 240, 1);
    }

    .logout_blk {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 2.4rem;

      .profile_blk {
        display: flex;

        .profile_img {
          width: 4rem;
          height: 4rem;
          border-radius: 75%;
          border: transparent;
          object-fit: contain;

          span {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 75%;
            background-color: $primary_color;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-weight: 600;
          }

          img {
            object-fit: contain;
            overflow: hidden;
          }
        }

        .profile_name {
          margin-left: 1.2rem;

          h5 {
            color: rgba(52, 64, 84, 1);
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 2rem;
            // margin-bottom: .8rem;
          }

          p {
            color: rgba(71, 84, 103, 1);
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2rem;
          }
        }
      }

      .logout_btn {
        background-color: transparent;
        border: transparent;

        i {
          font-size: 1.5rem;
          color: rgba(102, 112, 133, 1);
        }
      }
    }
  }
}