@import "../../../styles/commonStyles/mixins.scss";
@import "../../../styles/commonStyles/variables.scss";

#WizardPage {
  margin: 3rem 3.2rem;

  h2 {
    color: #101828;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.8rem;
    margin-bottom: 5.5rem;
  }

  .inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 15rem;

    h3 {
      color: #170f49;
      text-align: center;
      font-size: 3.4rem;
      font-weight: 700;
      line-height: 4.6rem;
      margin-bottom: 1.2rem;
      /* 135.294% */
    }

    p {
      color: #6f6c90;
      text-align: center;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 3rem;
    }

    .headline_form {
      border-radius: 3.4rem;
      border: 1px solid #eff0f6;
      background: #fff;
      /* Cards/Short Default */
      box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
      padding: 3.9rem 4.6rem;
      max-width: 69.8rem;
      width: 100%;
      margin: 0 auto;
      margin-top: 3.5rem;

      .progress_blk {
        max-width: 37rem;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 5rem;
      }

      hr {}

      .keyword {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        li {
          background-color: $primary_color;
          color: $white;
          font-weight: 500;
          padding: 0.5rem 0.8rem;
          font-size: 1.4rem;
          line-height: 1.8rem;
          margin-right: 1rem;
          border-radius: 2.4rem;
          display: flex;
          align-items: center;
          flex-direction: row-reverse;

          margin-bottom: 1rem;

          i {
            color: $black;
            margin-left: 0.5rem;
            font-size: 1.4rem;
            cursor: pointer;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .error-message {
        color: red;
      }

      label {
        color: #170f49;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2rem;
      }

      span {
        color: #a19fb5;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 3rem;
        display: block;
        margin-bottom: 1rem;
      }

      .input_blk {
        border-radius: 0.8rem;
        border: 1px solid var(--neutral-300, #eff0f6);
        background: var(--neutral-100, #fff);
        /* Input/Default */
        box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
        margin-bottom: 2.57rem;

        input {
          &::placeholder {
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 2rem;
            /* 111.111% */
          }
        }
      }

      .tab_btn {
        width: 100%;
        border-radius: 0.8rem;
        border: 1px solid var(--neutral-300, #eff0f6);
        background-color: #fff;
        /* Input/Default */
        box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
        height: 4.7rem;
        border: transparent;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 2rem;

        &.active {
          background-color: #e5e6e7;
        }
      }

      .tab_data {
        border-radius: 0.8rem;
        border: 1px dashed #000;
        background: #edffef;
        height: 4.7rem;
        color: #415a43;
        font-size: 1.5rem;
        font-style: italic;
        font-weight: 500;
        line-height: 2rem;
        padding: 0 2rem;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }

      .tab_data_red {
        border-radius: 0.8rem;
        border: 1px dashed #000;
        background: #ffd9d9;
        height: 4.7rem;
        color: #900;
        font-size: 1.5rem;
        font-style: italic;
        font-weight: 600;
        line-height: 2rem;
        padding: 0 2rem;
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
      }

      // generate headlines //

      .headline_list {
        li {
          display: flex;
          align-items: center;
          margin-bottom: 1.5rem;

          &:last-child {
            margin-bottom: 0;
          }

          /* The container */
          .label {
            width: 2.8rem;
            height: 2.6rem;
            min-width: 2.8rem;
            position: relative;
            display: block;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          /* Hide the browser's default checkbox */
          .label input {
            position: absolute;
            opacity: 1;
            cursor: pointer;
            height: 15px;
            width: 15px;
          }

          input[type="radio"]:checked {
            border: 2px solid $primary_color;
            background-color: $primary_color;
          }

          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            background-color: #fff;
            border-radius: 0.8rem;
            border: 1px solid #eff0f6;
            width: 2.8rem;
            height: 2.6rem;
            box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
          }

          /* On mouse-over, add a grey background color */
          .label:hover input~.checkmark {
            background-color: #fff;
          }

          /* When the checkbox is checked, add a blue background */
          .label input:checked~.checkmark {
            background-color: #2196f3;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          .label input:checked~.checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .label .checkmark:after {
            left: 10px;
            top: 5px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }

          p {
            color: #000;
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 2rem;
            text-align: left;
            margin-left: 1.2rem;
          }
        }
      }

      // generate headlines //

      //  outline //

      .outline_head {
        border-radius: 0.8rem;
        background: #6941c6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        margin-bottom: 1.5rem;

        color: #fff;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 2rem;

        /* The container */
        .radio_container {
          width: 2.8rem;
          height: 2.6rem;
          min-width: 2.8rem;
          position: relative;
          display: block;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        .radio_container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          background-color: #fff;
          border-radius: 0.8rem;
          border: 1px solid #eff0f6;
          width: 2.8rem;
          height: 2.6rem;
          box-shadow: 0px 2px 6px 0px rgba(19, 18, 66, 0.07);
        }

        /* On mouse-over, add a grey background color */
        .radio_container:hover input~.checkmark {
          background-color: #fff;
        }

        /* When the checkbox is checked, add a blue background */
        .radio_container input:checked~.checkmark {
          background-color: #2196f3;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .radio_container input:checked~.checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .radio_container .checkmark:after {
          left: 10px;
          top: 5px;
          width: 6px;
          height: 12px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }

      .outline_list {
        max-height: 45rem;
        overflow-y: auto;
        padding: 1.5rem;

        li {
          display: flex;
          margin-bottom: 1.5rem;

          // align-items: center;

          .count {
            display: initial;
            color: #000;
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 2rem;
            margin-bottom: initial;
          }

          p {
            color: #000;
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 2rem;
            text-align: left;
          }
        }
      }
    }

    .btns_blk {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1.6rem;
    }

    .re_generate_title_btn {
      display: flex;
      border-radius: 0.8rem;
      background: #000;
      box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);
      height: 6.1rem;
      padding: 2rem 4rem 2.1rem 4rem;
      color: #fff;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2rem;
      margin-top: 2.2rem;
      align-items: center;
      justify-content: end;
      width: fit-content;
      width: -moz-fit-content;
      border: initial;
    }

    .generate_title_btn {
      display: flex;
      border-radius: 0.8rem;
      background: #6941c6;
      box-shadow: 0px 3px 12px 0px rgba(74, 58, 255, 0.18);
      height: 6.1rem;
      padding: 2rem 4rem 2.1rem 4rem;
      color: #fff;
      text-align: center;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2rem;
      margin-top: 2.2rem;
      align-items: center;
      justify-content: end;
      width: fit-content;
      width: -moz-fit-content;
      border: initial;
    }
  }
}