#BulkGeneration {
    margin: 3rem 3.2rem;

    @media screen and (max-width: 767px) {
        margin: 3rem 2rem;
    }

    h2 {
        color: #101828;
        font-size: 3rem;
        font-weight: 600;
        line-height: 3.8rem;
        margin-bottom: 5.5rem;
    }

    .campaigns_result {
        background-color: #FFF;
        box-shadow: (0px 10px 60px rgba(226, 236, 249, 0.50));
        display: flex;
        flex-wrap: wrap;
        gap: 0 5.6rem;
        max-width: 100.3rem;
        width: 100%;
        margin: 0 auto;
        padding: 3.2rem 5rem;
        border-radius: 3.2rem;

        .campaigns_card {
            flex: 0 0 29%;
            display: flex;
            border-right: .1rem solid #F0F0F0;

            &:last-child {
                border-right: initial;
            }

            .img_blk {
                max-width: 8.4rem;
                height: 8.4rem;
                margin-right: 2rem;
            }

            .right_sec {
                display: flex;
                flex-direction: column;

                span {
                    color: #ACACAC;
                    font-size: 1.4rem;
                    font-weight: 400;
                    letter-spacing: -0.014rem;
                }

                h5 {
                    color: #333;
                    font-size: 3.2rem;
                    font-weight: 600;
                    line-height: 100%;
                    /* 3.2rem */
                    letter-spacing: -0.032rem;
                    margin-top: .4rem;
                    margin-bottom: .8rem;
                }

                p {
                    display: flex;
                    align-items: center;
                    color: #292D32;
                    font-size: 1.2rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: -0.012rem;

                    .green {
                        color: #00AC4F;
                        font-weight: 700;
                    }

                    .red {
                        color: #00AC4F;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .all_campaigns {
        background-color: #FFF;
        box-shadow: (0px 10px 60px rgba(226, 236, 249, 0.50));
        padding: 1rem 3.94rem 4rem;
        max-width: 106.3rem;
        width: 100%;
        margin: 0 auto;
        border-radius: 3.2rem;
        margin-top: 6.4rem;

        .campaign_btn {
            margin-top: 1rem;
            margin-left: auto;
            display: flex;
            width: 15.9rem;
            padding: 1rem 1.6rem;
            justify-content: center;
            align-items: center;
            gap: 0.8rem;
            border-radius: 0.8rem;
            border: 1px solid #7F56D9;
            background: #7F56D9;
            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: #FFF;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 600;
            line-height: 2rem;

        }

        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                color: #000;
                // font-family: Poppins;
                font-size: 2.2rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                // letter-spacing: -0.022rem;
                // padding-left: 1.8rem;
            }

            .right_sec {
                display: flex;
                align-items: center;
                margin-top: .6rem;

                .input_blk {
                    max-width: 22.381rem;
                    height: 3.8rem;
                    border-radius: 1rem;
                    background: #F9FBFF;
                    position: relative;
                    border: transparent;
                    margin-bottom: 0;
                    margin-right: 1.6rem;

                    input {
                        background-color: #F9FBFF;
                        border: transparent;
                        height: 100%;
                        padding-left: 4rem;

                        &::placeholder {
                            color: #B5B7C0;
                            font-size: 1.2rem;
                            font-weight: 400;
                            letter-spacing: -0.012rem;
                            padding-left: .5rem;
                        }
                    }

                    i {
                        color: rgba(126, 126, 126, 1);
                        font-size: 2rem;
                    }

                    .icon {
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 2.4rem;
                        height: 2.4rem;
                    }
                }

                .sort_blk {
                    display: flex;
                    align-items: center;
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    color: #B5B7C0;
                    border-radius: 1rem;
                    background: #F9FBFF;
                    max-width: 15.9568rem;
                    height: 3.8rem;
                    padding: 0 1.5rem;

                    select {
                        border: transparent;
                        background-color: initial;
                        max-width: 7rem;
                        font-size: 1.2rem;
                        color: #000;

                        &:active,
                        :focus,
                        :focus-within,
                        :focus-visible,
                        :hover {
                            border: transparent;
                            outline: none;
                            box-shadow: initial;
                        }



                    }
                }
            }
        }

        .campaigns_table {
            overflow-x: auto;
            width: 100%;
            margin-top: 4.5rem;

            table {
                table-layout: fixed;
                width: 100%;
                border-collapse: collapse;

                thead {
                    tr {
                        border-bottom: .1rem solid rgba(238, 238, 238, 1);

                        td {
                            color: #B5B7C0;
                            font-size: 1.4rem;
                            font-weight: 500;
                            letter-spacing: -0.014rem;
                            padding: 1.4rem 0;
                            text-align: center;

                        }
                    }
                }

                tbody {
                    .table-body-no-content {
                        .content-wrapper {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;
                        }
                    }

                    tr {
                        border-bottom: .1rem solid rgba(238, 238, 238, 1);
                        cursor: pointer;

                        td {
                            color: #292D32;
                            font-size: 1.4rem;
                            font-weight: 500;
                            letter-spacing: -0.014rem;
                            padding: 2.4rem 0;
                            text-align: center;

                            .progress {
                                height: 2.8rem;
                                border-radius: 0.4rem 0rem 0rem 0.4rem;
                                background: #E4EAE8;

                                .progress-bar {
                                    background-color: #2FD1AB;
                                }
                            }

                            .tag {
                                display: flex;
                                width: 9.1rem;
                                padding: 0.4rem 1.2rem;
                                justify-content: flex-end;
                                align-items: center;
                                margin: 0 auto;
                                gap: 1rem;
                                border-radius: 0.4rem;
                                border: 1px solid #00B087;
                                background: rgba(22, 192, 152, 0.38);
                                color: #008767;
                                font-size: 1.4rem;
                                font-weight: 500;
                                letter-spacing: -0.014rem;
                            }

                        }
                    }
                }
            }
        }
    }
}