@import '../../styles/commonStyles/variables.scss';

#contact_us {
    // padding: 0 6rem;
    margin: 18rem 10rem 5rem;
    height: calc(100vh - 18rem);
    background-color: $white;

    @media screen and (max-width:767px) {
        margin: 18rem 2rem 5rem;
        height: 100vh;
    }

    .heading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 6rem;
        // padding-top: 15rem;
        // padding-bottom: 5rem;

        .contact-heading {
            color: $black;
            font-weight: 800;

        }

        .contact-description {
            color: #717171;
            margin-top: 20px;
        }
    }

    .main-section {
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // align-items: center;
        padding-bottom: 5rem;

        h1 {

            color: $black;

            @font-face {
                font-family: "Noe Display Bold";
                src: url("https://db.onlinewebfonts.com/t/d5057d1717ee8f3db3350cf9a6252f82.eot");
                src: url("https://db.onlinewebfonts.com/t/d5057d1717ee8f3db3350cf9a6252f82.eot?#iefix")format("embedded-opentype"),
                    url("https://db.onlinewebfonts.com/t/d5057d1717ee8f3db3350cf9a6252f82.woff2")format("woff2"),
                    url("https://db.onlinewebfonts.com/t/d5057d1717ee8f3db3350cf9a6252f82.woff")format("woff"),
                    url("https://db.onlinewebfonts.com/t/d5057d1717ee8f3db3350cf9a6252f82.ttf")format("truetype"),
                    url("https://db.onlinewebfonts.com/t/d5057d1717ee8f3db3350cf9a6252f82.svg#Noe Display Bold")format("svg");
            }

            color: $black;
            font-size: 6.4rem;
            font-weight: 700;
            margin-bottom: 2rem;
        }

        .heading-h3 {
            color: $black;
            margin-bottom: 1.5rem;
        }

        .description {
            color: $black;
            font-size: 2rem;
            font-weight: 400;
            margin-bottom: 2rem;
        }

        .link {
            display: block;
            color: $black;
            font-size: 1.6rem;
            font-weight: 400;
            text-decoration-line: underline !important;
            margin-bottom: 2rem;
        }
    }

    .contact_form {
        label {
            color: #000;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 1.5rem;
        }

        .input_blk {
            height: 4.6rem;
            border: transparent;

            input {
                background-color: rgba(247, 247, 247, 1);
                padding: 2.5rem;
                border: transparent;
            }

            .text_area {
                background-color: rgba(247, 247, 247, 1);
                min-height: 16rem;
                padding: 1.5rem;
                resize: none;
                width: 100%;
                border: transparent;
            }
        }
    }
}