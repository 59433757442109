@import "../../../../styles/commonStyles/mixins.scss";
@import "../../../../styles/commonStyles/variables.scss";

#preview-article {
  // height: 100vh;
  //   min-height: calc(100vh - 19rem);
  margin: 3rem 3.2rem;

  @media screen and (max-width: 767px) {
    margin: 3rem 2rem;
  }


  h2 {
    color: #101828;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.8rem;
    margin-bottom: 5.5rem;
  }

  //   display: flex;
  //   justify-content: center;

  @media screen and (max-width: "992px") {}

  .dashboard_cards_sec {
    background-color: $white;
    margin: 0 auto;
    // margin-top: 5rem;
    // padding: 0 7rem;

    .dashboard_Card {
      // background-color: $black;
      box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
      padding: 3rem 2rem;
      border-radius: 1rem;
      max-width: 50rem;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border: 0.1rem solid transparent;
      cursor: pointer;

      &:hover {
        border-color: $primary_color;
      }

      .img_blk {
        width: 100%;
        height: 30rem;
        margin-bottom: 4rem;

        img {
          object-fit: contain;
        }
      }

      .blog_title {
        font-size: 2.1rem;
        line-height: 2.4rem;
        color: $black;
        margin-bottom: 1rem;
        font-weight: 600;
        text-align: left;
      }

      .blog_desc {
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: $black;
        margin-bottom: 0rem;
        font-weight: 400;
        text-align: left;
      }
    }
  }
}