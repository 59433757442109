@import "../../../../styles/commonStyles/mixins.scss";
@import "../../../../styles/commonStyles/variables.scss";

#pricing-plan-listing {
    margin: 3.2rem 6.2rem;


    h1 {
        color: rgba(16, 24, 40, 1);
        font-size: 3rem;
        line-height: 3.6rem;
        font-weight: 600;
        margin-bottom: 2.2rem;
    }

    h2 {
        color: rgba(16, 24, 40, 1);
        font-size: 6rem;
        line-height: 7.2rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 6.4rem;
    }

    .radio_blk {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4.8rem;
        gap: 1rem;

        span {
            color: rgba(0, 0, 0, 0.50);
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 120%;

            &.active {
                color: #7F56D9;
            }

            /* 2.16rem */
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked+.slider {
            background-color: #6941C6;
        }

        input:focus+.slider {
            box-shadow: 0 0 1px #2196F3;
        }

        input:checked+.slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }

        /* Rounded sliders */
        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }
    }

    .pricing_card {
        box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
        padding: 2.4rem;
        background-color: #fff;
        border-radius: 0.8rem;
        transition: all ease-in-out .4s;

        &:hover {
            box-shadow: 0px 5px 30px 2px rgba(105, 65, 198, 0.20), 0px 2px 8px 0px rgba(158, 119, 237, 0.20);
        }

        h3 {
            color: rgba(26, 26, 26, 1);
            font-size: 2.7rem;
            line-height: 3.2rem;
            font-weight: 700;
            margin-bottom: 1.6rem;

            span {
                color: rgba(26, 26, 26, 1);
                font-size: 1.2rem;
                line-height: 1.4rem;
                font-weight: 400;
            }
        }

        p {
            color: #667085;
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 140%;
            /* 2.52rem */
        }

        hr {
            color: rgba(217, 217, 217, 1);
            margin: 3.2rem 0;
        }

        ul {
            li {
                position: relative;
                color: #1A1A1A;
                font-size: 1.8rem;
                font-weight: 400;
                line-height: 140%;
                margin-bottom: 1.6rem;
                display: block;
                display: flex;
                align-items: center;
                /* 2.52rem */

                .icon_blk {
                    margin-right: 1.2rem;
                }

                // &::after {
                //     background-image: url('../../../assets//images/checkicon.svg');
                //     background-repeat: no-repeat;
                //     // background-size: contain;
                //     width: 2.8rem;
                //     height: 2.8rem;
                //     // margin-right: 2rem;
                //     position: absolute;
                //     left: 2rem;

                // }


            }
        }

        .try_btn {
            max-width: 24rem;
            width: 100%;
            margin: 0 auto;
            margin-top: 3.2rem;
            display: flex;
            padding: 1.6rem 0.8rem;
            justify-content: center;
            align-items: center;
            gap: 0.4rem;
            border-radius: 0.4rem;
            border: 2px solid #9E77ED;

            background-color: #fff;

            color: #7F56D9;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            transition: all ease-in-out .3s;
            /* 2.52rem */

            &:hover {
                background-color: rgba(105, 65, 198, 1);
                color: #fff;
                border: 2px solid rgba(105, 65, 198, 1);

            }
        }
    }

    .DemoPickerWrapper {
        padding: 0 12px;
        //   font-family: "Source Code Pro", monospace;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        background: white;
        margin: 24px 0 48px;
        width: 100%;
      }
      
      .DemoPicker {
        font-size: 18px;
        border-radius: 3px;
        background-color: white;
        height: 48px;
        //   font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        border: 0;
        width: 100%;
        color: #6772e5;
        outline: none;
        background: transparent;
      
        -webkit-appearance: none;
      }
      
      .DemoWrapper {
        // margin-top: 200px;
        margin: 0px auto auto auto;
        max-width: 500px;
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        //   height: 100vh;
      }
      
      .Demo {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 40%;
      }

      label {
        color: #6b7c93;
        font-weight: 300;
        letter-spacing: 0.025em;
        width: 100%;
      }
      
      .stripe_btn {
        white-space: nowrap;
        border: 0;
        width: 100%;
        outline: 0;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.025em;
        background-color: $primary_color;
        text-decoration: none;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
        margin-top: 10px;
      }
      
      .stripe_btn:hover {
        color: $primary_color;
        cursor: pointer;
        border: 2px solid $primary_color;
        background-color: #fff;
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
      }
      
      .stripe_input,
      .StripeElement {
        display: block;
        margin: 10px 0 20px 0;
        //   max-width: 500px;
        padding: 10px 14px;
        font-size: 1em;
        //   font-family: "Source Code Pro", monospace;
        box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
          rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
        border: 0;
        outline: 0;
        border-radius: 4px;
        background: white;
        width: 100%;
      }
      
      .stripe_input::placeholder {
        color: #aab7c4;
      }
      
      .stripe_input:focus,
      .StripeElement--focus {
        box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
          rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
        -webkit-transition: all 150ms ease;
        transition: all 150ms ease;
      }
      
      .StripeElement.IdealBankElement,
      .StripeElement.FpxBankElement,
      .StripeElement.PaymentRequestButton {
        padding: 0;
      }
      
      .StripeElement.PaymentRequestButton {
        height: 40px;
      }
      
      .stripe-form-pricing {
        margin: 25px;
      }
}