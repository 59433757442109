@import "../../styles/commonStyles/mixins.scss";
@import "../../styles/commonStyles/variables.scss";

#header {
    background: $white;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.1);
    border-radius: 7rem;
    margin: 3rem 10rem 0 10rem;
    padding: 0 2rem;
    height: 7.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    @media screen and (max-width:768px) {
        padding: 0 2rem;
    }

    .menu_icon {
        display: none;
        width: 4rem;
        min-width: 4rem;
        height: 4rem;

        // margin-right: 1rem;

        i {
            color: $black;
            font-size: 2.5rem;
        }

        @media screen and (max-width: 768px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .logo_blk {
        width: 10rem;
        height: 10rem;
        min-width: 10rem;

        @media screen and (max-width: 768px) {
            position: absolute;
            left: 10%;
        }

        img {
            object-fit: contain;
        }
    }

    .nav_blk {
        display: flex;
        align-items: center;

        nav {
            display: flex;
            align-items: center;
            list-style-type: none;
            margin-right: 7rem;
            transition: all ease-in-out 0.3s;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            @media screen and (max-width:768px) {
                position: fixed;
                top: 8rem;
                left: -250px;
                background: #ffff;
                flex-direction: column;
                align-items: self-start;
                width: 250px;
                padding: 5rem 3rem;
                height: 100%;
                transition: all ease-in-out 0.3s;
            }

            &.active {
                left: 125px;
            }


            li {
                margin-right: 3rem;
                cursor: pointer;

                @media screen and (max-width:768px) {
                    margin-bottom: 3rem;
                    margin-right: initial;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    font-family: 'Manrope', sans-serif;
                    color: $black;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    font-weight: 600;
                    text-decoration: none;

                    &.active {
                        color: $primary_color;
                        font-weight: 600;
                    }

                    &:hover {
                        color: $primary_color;
                    }
                }
            }
        }

        .btns_blk {
            display: flex;
            align-items: center;


            .btn_without_border {
                font-family: 'Manrope', sans-serif;
                border: .1rem solid transparent;
                border-radius: .4rem;
                background-color: transparent;
                color: $black !important;
                font-size: 1.8rem;
                line-height: 3.2rem;
                font-weight: 700;
                height: 5rem;
                padding: 1rem;
                transition: all ease-in-out 0.4s;
                margin-right: 3rem;
                cursor: pointer;
                display: block;
                text-decoration: none;



            }

            .btn_with_border {
                font-family: 'Manrope', sans-serif;
                border: .2rem solid black;
                border-radius: .4rem;
                background-color: transparent;
                color: $white;
                font-size: 1.8rem;
                line-height: 3.2rem;
                font-weight: 700;
                height: 4.8rem;
                width: 14.2rem;
                background-color: $black;
                border-radius: 5.8rem;
                transition: all ease-in-out 0.4s;
                // margin-right: 3rem;
                font-weight: 600;
                cursor: pointer;



            }
        }
    }

}