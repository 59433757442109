@import "../../../styles/commonStyles/mixins.scss";
@import "../../../styles/commonStyles/variables.scss";

#login {
    position: relative;
    height: 100vh;

    // margin: 6rem auto 0 auto;

    .row {
        height: 100%;
    }

    .inner {

        max-width: 36rem;
        width: 100%;
        margin: 0 auto;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        // position: absolute;
        // top: 50%;
        // transform: translateY(-50%);

        .remember_text {
            display: flex;
            color: $black;
            margin-left: 1rem;
            font-size: 1.2rem;
            font-weight: 400;
            line-height: 2rem;
            /* 166.667% */
            letter-spacing: 0.03rem;
        }

        .toggle_btn {
            display: flex;
            align-items: center;
        }

        .logo_blk {
            width: 20rem;
            height: 10rem;
            min-width: 10rem;
            // margin: 0 auto;
            margin-bottom: 1rem;

            img {
                object-fit: contain;
            }
        }

        h1 {
            color: #1A1A1A;
            font-feature-settings: 'clig' off, 'liga' off;
            /* Title */
            font-size: 2rem;
            font-weight: 600;
            line-height: 2.8rem;
            margin-bottom: 3rem;
            /* 140% */
        }

        h2 {
            text-align: center;
            margin-bottom: 3rem;
            color: $black;

        }

        .signup_form {
            max-width: 50rem;
            width: 100%;
            margin: 0 auto;

            label {
                color: rgba(0, 0, 0, 1);
                font-size: 1.1rem;
                font-weight: 500;
                line-height: 1.2rem;
                /* 109.091% */
                letter-spacing: 0.03rem;
            }

            .input_blk {
                width: 36rem;
                // padding: 0.8rem 0.8rem 0.8rem 1.6rem;

                input {
                    background-color: #f2f2f2;

                    &::placeholder {
                        color: rgba(128, 128, 128, 1);
                    }
                }

            }

            .forgot_blk {
                display: flex;
                align-items: center;
                // justify-content: space-between;
                justify-content: end;
                margin-top: 2rem;

                .switch {
                    position: relative;
                    display: inline-block;
                    width: 40px;
                    height: 20px;
                }

                .switch input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #ccc;
                    -webkit-transition: .4s;
                    transition: .4s;
                }

                .slider:before {
                    position: absolute;
                    content: "";
                    height: 20px;
                    width: 20px;
                    left: 0px;
                    bottom: 0px;
                    background-color: white;
                    -webkit-transition: .4s;
                    transition: .4s;
                }

                input:checked+.slider {
                    background-color: #2196F3;
                }

                input:focus+.slider {
                    box-shadow: 0 0 1px #2196F3;
                }

                input:checked+.slider:before {
                    -webkit-transform: translateX(20px);
                    -ms-transform: translateX(20px);
                    transform: translateX(20px);
                }

                /* Rounded sliders */
                .slider.round {
                    border-radius: 34px;
                }

                .slider.round:before {
                    border-radius: 50%;
                }

                .forgot {

                    // color: #007AFF;
                    color: $primary_color;
                    text-align: right;
                    font-feature-settings: 'clig' off, 'liga' off;
                    font-size: 1.2rem;
                    font-weight: 400;
                    line-height: 2rem;
                    /* 166.667% */
                    letter-spacing: 0.03rem;
                }
            }

            .check_mark {
                display: flex;
                // align-items: center;
                margin-top: 3.2rem;

                .checkbox {
                    height: 2.5rem;
                    width: 2.5rem;
                    margin-right: 1.5rem;
                }

                .terms_text {
                    font-size: 1.6rem;
                    line-height: 2.1rem;
                    color: lightgray;

                }
            }

            .login_acc {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.6rem;
                line-height: 2.1rem;
                color: $black;
                font-weight: 400;
                margin-top: 3.2rem;


                .blue_text {
                    color: $primary_color;
                    font-weight: 600;
                }
            }

            .google_login {
                display: flex;
                align-items: center;
                width: 50%;
                margin: 0 auto;
                font-size: 1.8rem;
                line-height: 2.4rem;
                color: $black;
                padding: 0 2rem;
                border-radius: .8rem;
                background-color: $white;
                border: .1rem solid #D6D6D7;
                height: 5rem;
                cursor: pointer;

                &:hover {
                    border-color: $primary_color
                }

                .icon_blk {
                    width: 3rem;
                    min-width: 3rem;
                    height: 3rem;
                    margin-right: 1rem;

                    img {
                        object-fit: contain;
                    }
                }
            }

            .googleLogin-Package {
                display: flex !important;
                border-radius: .8rem !important;
                background-color: #333 !important;
                border: .2rem solid #a8a8a8 !important;
                box-shadow: none !important;
                align-items: center !important;
                justify-content: center;
                margin: 0 auto !important;
                margin-top: 3.2rem !important;
                padding: 0px 1rem !important;
                width: 100% !important;
                font-size: 16px !important;
                color: #fff !important;

                &>div {
                    background-color: initial !important;
                    border: initial !important;
                }



                svg {
                    margin-top: 5px;
                }
            }

            hr {
                color: #E5E5E5;
            }


            .signup_btn {
                border: transparent;
                border-radius: .8rem;
                width: 100%;
                height: 5rem;
                // background-color: rgba(0, 122, 255, 1);
                background-color: $primary_color;
                color: $white;
                font-size: 2.1rem;
                line-height: 2.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 3.2rem 0;
                cursor: pointer;
            }
        }

    }

    .img_blk {
        background-color: #007AFF;
        width: 100%;
        height: 100%;
    }


}