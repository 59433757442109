@import "../../../styles/commonStyles/mixins.scss";
@import "../../../styles/commonStyles//variables.scss";

#setting {
	margin: 6rem 0 0rem 0;
	// margin-top: 6rem 0 0rem 0;
	height: calc(100vh - 12rem);

	.title {
		font-size: 2.4rem;
		font-weight: 600;
		text-align: center;
		margin: 0 auto;
		margin-bottom: 3rem;
	}

	.image_blk {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 5rem;
		text-align: center;

		.img_blk {
			position: relative;
			width: 8rem;
			min-width: 8rem;
			height: 8rem;
			border-radius: 75%;
			border: transparent;
			// padding: .2rem;
			background-color: $primary_color;

			img {
				object-fit: contain;
				width: 100%;
				height: 100%;
				margin: 0 auto;
				border-radius: 75%;
				overflow: hidden;
			}

			i {
				position: absolute;
				color: $white;
				bottom: 1px;
				right: 15px;
			}

			span {
				font-size: 5.1rem;
				// object-fit: contain;
			}
		}
	}

	.edit_btn {
		width: 100%;
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border: initial;
		background-color: initial;
		color: $primary_color;
		font-size: 2.1rem;
		line-height: 2.4rem;
		font-weight: 600;
	}

	.setting_form {
		max-width: 50rem;
		width: 100%;
		margin: 0 auto;
		position: relative;
		// padding: 2rem 1.5rem;
		// box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
		// background-color: $white;
		// border-radius: 1rem;

		label {
			color: $black;
		}

		.pricing_link {
			color: $primary_color;
			// margin: 0 auto;
			// margin-top: 3rem;
			text-align: left;
			margin-bottom: 0;
			display: block;
		}

		.save_btn {
			width: 100%;
			background-color: $secondary_color;
			color: $white;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 5rem;
			font-size: 2.1rem;
			line-height: 2.8rem;
			font-weight: 600;
			border: 0.1rem solid transparent;
			border-radius: 0.8rem;
			margin-top: 2rem;

			&:hover {
				border-color: $white;
			}
		}

		.cancel_btn {
			width: 100%;
			background-color: $white;
			color: $black;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 5rem;
			font-size: 2.1rem;
			line-height: 2.8rem;
			font-weight: 600;
			border: 0.1rem solid $black;
			border-radius: 0.8rem;
			margin-top: 2rem;

			&:hover {
				border-color: $black;
			}
		}
	}
}
