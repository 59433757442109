@import "../../../styles/commonStyles/variables.scss";

#pricing_details {

    @media screen and (max-width: 767px) {
        margin: 0rem 2rem;
    }

    .title {
        font-size: 5.1rem;
        line-height: 6.4rem;
        color: $black;
        text-align: center;
        margin-bottom: 5rem;


        @media screen and (max-width: 769px) {
            font-size: 3.1rem;
            line-height: 4.1rem;
        }
    }

    .table_outer {
        overflow-x: auto;
        border: .1rem solid $gray_color;
        border-radius: .8rem;
        width: 100%;
        margin-bottom: 5rem;

        .plans_table {
            width: 100%;

            thead {
                background-color: #7F56D9;

                th {
                    flex: 1;


                    td {
                        padding: 2rem;

                        h3 {
                            color: $white;
                            font-size: 2.1rem;
                            margin-bottom: 0;

                        }
                    }
                }
            }

            tbody {
                tr {
                    // border: .1rem solid $gray_color;

                    td {
                        padding: 2rem;
                        text-align: justify;
                        color: $black;
                        font-weight: 600;

                        i {
                            font-size: 2.4rem;
                            color: green;
                        }
                    }
                }
            }
        }
    }


}