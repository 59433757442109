@import "../../../styles/commonStyles/mixins.scss";
@import "../../../styles/commonStyles/variables.scss";

#apiDocumentation {
  margin: 3rem 3.2rem;

  @media screen and (max-width: 767px) {
    margin: 3rem 2rem;
  }

  h2 {
    color: #101828;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.8rem;
    margin-bottom: 5.5rem;
  }

  .inner {
    margin-top: 6.5rem;

    h5 {
      color: #101828;
      font-size: 2rem;
      font-weight: 600;
      line-height: 2.8rem;
    }

    p {
      color: #475467;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 2rem;
    }

    .private_key {
      max-width: 75.2rem;
      width: 100%;
      border: 2px dashed #D3D3D3;
      background: rgba(217, 217, 217, 0.00);
      margin-top: 2.7rem;
      padding: 1.2rem;

      h6 {
        color: #101828;
        font-size: 2rem;
        font-weight: 600;
        line-height: 2.8rem;
      }

      .key_blk {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        label {
          color: #585757;
          font-size: 1.8rem;
          font-style: italic;
          font-weight: 600;
          line-height: 2.8rem;

        }

        input {
          max-width: 59.9rem;
          width: 100%;
          height: 2.9rem;
          border-radius: 0.8rem;
          background: rgba(219, 219, 219, 0.33);
          margin-left: 1.4rem;
          margin-right: .4rem;
          border: initial;
          padding: 1.5rem;
        }

        .icon_blk {
          cursor: pointer;
        }

      }
    }

    .api_restriction_blk {
      margin-top: 3.5rem;
      max-width: 56rem;
      padding: 1.6rem 2.4rem 1.6rem 1.6rem;
      border-radius: 0.6rem;
      border: 1px solid #E5E9EB;
      background: #F6F8F9;

      .top {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        color: #252C32;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 2.4rem;
        letter-spacing: -0.0084rem;

        a {
          font-weight: 900;
        }
      }

      ul {
        margin-left: 4rem;
        list-style-type: disc;
        margin-top: .5rem;

        li {
          margin-bottom: .5rem;
          color: #5B6871;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 2.4rem;
          letter-spacing: -0.0084rem;

          b {
            color: #5B6871;
            font-size: 1.4rem;
            font-weight: 700;
            line-height: 2.4rem;
            letter-spacing: -0.0084rem;
          }

        }
      }
    }

    h4 {
      color: #252C32;
      font-size: 2rem;
      font-weight: 600;
      line-height: 3.2rem;
      letter-spacing: -0.038rem;
      margin-top: 6.5rem;
    }

    .title_blk {
      max-width: 75.2rem;
      width: 100%;
      height: 6rem;
      padding: 2rem 1.6rem;
      flex-shrink: 0;
      border-radius: 0.8rem;
      background: #F9FAFB;
      color: #000;
      font-size: 1.8rem;
      font-style: italic;
      font-weight: 300;
      line-height: 3.2rem;
      letter-spacing: -0.0342rem;
      display: flex;
      align-items: center;
    }

    .code_blk {
      border-radius: 0.6rem 0.6rem 0rem 0rem;
      border: 1px solid #DDE2E4;
      max-width: 56rem;
      width: 100%;
      margin-top: 2rem;

      .top_blk {
        color: #252C32;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2.4rem;
        letter-spacing: -0.0084rem;
        border-bottom: .1rem solid #DDE2E4;
        padding: 1rem 1.5rem;
      }

      .code {
        max-height: 25rem;
        height: 25rem;
        overflow-y: auto;
        position: relative;
        font-size: 1.4rem;
        color: #000;
        padding: 1.5rem 1rem;

        svg {

          position: absolute;
          bottom: 10px;
          right: 20px;
          cursor: pointer;
        }
      }
    }
  }
}