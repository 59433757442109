@import "../../styles/commonStyles/variables.scss";

#privacy_terms {
  margin: 3rem 3.2rem;

  h2 {
    color: #101828;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.8rem;
    margin-bottom: 10rem;
  }

  .inner {
    padding: 0 14rem;

    h3 {
      color: $black;
      font-size: 4.1rem;
      font-weight: 600;
      line-height: 2.4rem;
      margin-bottom: 3rem;
      /* 58.537% */
    }

    .text_blk {
      margin-bottom: 3rem;

      h4 {
        color: $black;
        font-size: 3.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 4rem;
        /* 125% */
      }

      p {
        color: $black;
        font-size: 2.2rem;
        font-weight: 300;
        line-height: 4rem;
      }
    }
  }
}
